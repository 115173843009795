import {
  type PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import { useInView } from 'react-intersection-observer';
import { useInterval, usePrevious } from 'react-use';
import useMeasure from 'react-use-measure';
import useSWRImmutable from 'swr/immutable';

import { type Media } from '@lp-lib/media';

import astronaut from '../../assets/img/astronaut-avatar.png';
import { useInstance } from '../../hooks/useInstance';
import { apiService } from '../../services/api-service';
import type { JoyCapture as JoyCaptureType } from '../../types/joyCapture';
import { ResizeObserver } from '../../utils/ResizeObserver';
import { Loading } from '../Loading';
import { usePrivacySettings } from '../Settings/usePrivacySettings';
import { useUser } from '../UserContext';

type Query = {
  uid: string;
  sessionId?: string;
};

type UseJoyCapture = {
  joyCapture: JoyCaptureType | undefined;
  isValidating: boolean;
  error: Error | undefined;
  refresh: () => Promise<JoyCaptureType | undefined>;
};

async function loadJoyCapture(
  query: Query
): Promise<JoyCaptureType | undefined> {
  const resp = await apiService.joyCapture.query({
    uids: [query.uid],
    sessionId: query.sessionId,
  });

  const joyCaptures = resp.data.joyCaptures;
  return joyCaptures.length > 0 ? joyCaptures[0] : undefined;
}

function useJoyCapture(query: Query): UseJoyCapture {
  const { data, error, isValidating, mutate } = useSWRImmutable<
    JoyCaptureType | undefined,
    Error
  >(['/joy-captures/', query], () => loadJoyCapture(query), {
    shouldRetryOnError: false,
  });

  return {
    joyCapture: data,
    isValidating,
    error,
    refresh: mutate,
  };
}

export interface JoyCaptureRenderer {
  register(el: HTMLDivElement): void;
  unregister(el: HTMLDivElement): void;
}

export function useJoyCaptureRenderer(): JoyCaptureRenderer {
  const refMap = useInstance(() => new Map<HTMLDivElement, number>());
  const register = useCallback(
    (el: HTMLDivElement) => {
      refMap.set(el, 0);
    },
    [refMap]
  );
  const unregister = useCallback(
    (el: HTMLDivElement) => {
      refMap.delete(el);
    },
    [refMap]
  );

  useInterval(() => {
    if (refMap.size === 0) return;
    requestAnimationFrame(() => {
      for (const [el, frame] of refMap) {
        const nextFrame = (frame + 1) % 24;
        el.style.backgroundPositionY = `-${100 * nextFrame}%`;
        refMap.set(el, nextFrame);
      }
    });
  }, 125);

  return { register, unregister };
}

export function useJoyCaptureRenderRegister(
  ref: {
    current: HTMLDivElement | null;
  },
  renderer?: JoyCaptureRenderer,
  enabled = true
): void {
  useEffect(() => {
    if (ref.current === null || renderer === undefined) return;

    const el = ref.current;
    if (!el) return;
    if (enabled) {
      renderer.register(el);
    } else {
      renderer.unregister(el);
    }

    return () => {
      renderer.unregister(el);
    };
  }, [renderer, ref, enabled]);
}

export type JoyCaptureStyles = {
  size?: string;
  border?: string;
  text?: string;
  shape?: string;
  bg?: string;
  aspectFill?: string;
};

function JoyCaptureContainer(
  props: PropsWithChildren<{
    styles?: JoyCaptureStyles;
  }>
): JSX.Element {
  return (
    <div className={props.styles?.size ?? 'w-24 h-24'}>
      <div
        className={`
          w-full h-full overflow-hidden 
          flex items-center justify-center 
          ${props.styles?.bg ?? ''}
          ${props.styles?.border ?? 'border-none'}
          ${props.styles?.shape ?? 'rounded-full'}
        `}
      >
        {props.children}
      </div>
    </div>
  );
}

function AnimatedFilmStripJoyCapture(props: {
  src: string;
  styles?: JoyCaptureStyles;
  noAnimate?: boolean;
  renderer?: JoyCaptureRenderer;
}) {
  const ref = useRef<HTMLDivElement | null>(null);
  const [measureRef, bounds] = useMeasure({
    debounce: 100,
    polyfill: ResizeObserver,
  });

  const [inViewRef, inView, entry] = useInView();
  const isInView = inView && !!entry;
  useJoyCaptureRenderRegister(
    ref,
    props.renderer,
    !props.noAnimate && isInView
  );

  const fill = useMemo(() => {
    if (props.styles?.aspectFill) return props.styles.aspectFill;

    const isLandscape = bounds.width > bounds.height;
    return isLandscape ? 'w-full' : 'h-full';
  }, [bounds.width, bounds.height, props.styles?.aspectFill]);

  return (
    <JoyCaptureContainer styles={props.styles}>
      <div ref={measureRef} className='w-full h-full'>
        <div
          ref={inViewRef}
          className='w-full h-full flex items-center justify-center'
        >
          <div
            ref={ref}
            className={fill}
            style={{
              backgroundImage: `url('${props.src}')`,
              backgroundPositionX: 'center',
              backgroundSize: '100%',
              aspectRatio: '4 / 3',
            }}
          />
        </div>
      </div>
    </JoyCaptureContainer>
  );
}

function FilmStripJoyCapture(props: {
  src: string;
  styles?: JoyCaptureStyles;
  renderer?: JoyCaptureRenderer;
  noAnimate?: boolean;
}) {
  return (
    <AnimatedFilmStripJoyCapture
      src={props.src}
      styles={props.styles}
      noAnimate={props.noAnimate}
      renderer={props.renderer}
    />
  );
}

function GifJoyCapture(props: {
  media: Media;
  styles?: JoyCaptureStyles;
  noAnimate?: boolean;
}) {
  const url =
    props.noAnimate && props.media.firstThumbnailUrl
      ? props.media.firstThumbnailUrl
      : props.media.url;

  return (
    <JoyCaptureContainer styles={props.styles}>
      <img
        src={url}
        className='w-full h-full object-cover object-center'
        alt='Joy Capture'
      />
    </JoyCaptureContainer>
  );
}

export function InitialsJoyCapture(props: {
  initials: string;
  styles?: JoyCaptureStyles;
}): JSX.Element {
  const styles: JoyCaptureStyles = {
    ...props.styles,
    bg: props.styles?.bg ?? 'bg-placeholder-1 bg-no-repeat bg-cover bg-center',
    border: props.styles?.border ?? 'border border-secondary',
    text:
      props.styles?.text ??
      'text-white text-4xl font-semibold uppercase w-full flex-1 text-center',
  };

  return (
    <JoyCaptureContainer styles={styles}>
      <div className={styles.text}>{props.initials}</div>
    </JoyCaptureContainer>
  );
}

export function JoyCapture(props: {
  joyCapture: JoyCaptureType | null | undefined;
  styles?: JoyCaptureStyles;
  noAnimate?: boolean;
  renderer?: JoyCaptureRenderer;
  fallbackImageSrc?: string;
  noInitials?: boolean;
}): JSX.Element | null {
  if (props.joyCapture?.media) {
    return (
      <GifJoyCapture
        media={props.joyCapture.media}
        styles={props.styles}
        noAnimate={props.noAnimate}
      />
    );
  } else if (props.joyCapture?.preSignedS3Url) {
    return (
      <FilmStripJoyCapture
        src={props.joyCapture?.preSignedS3Url}
        renderer={props.renderer}
        styles={props.styles}
        noAnimate={props.noAnimate}
      />
    );
  } else if (props.joyCapture?.initials && !props.noInitials) {
    return (
      <InitialsJoyCapture
        initials={props.joyCapture?.initials}
        styles={props.styles}
      />
    );
  } else {
    return (
      <JoyCaptureContainer styles={props.styles}>
        <img
          src={props.fallbackImageSrc ?? astronaut}
          alt='No available avatar'
        />
      </JoyCaptureContainer>
    );
  }
}

export function UserJoyCapture(props: {
  uid: string;
  sessionId?: string;
  styles?: JoyCaptureStyles;
  noAnimate?: boolean;
  renderer?: JoyCaptureRenderer;
  fallbackImageSrc?: string;
  noInitials?: boolean;
}): JSX.Element | null {
  const { joyCapture, isValidating } = useJoyCapture({
    uid: props.uid,
    sessionId: props.sessionId,
  });

  if (isValidating) {
    return (
      <JoyCaptureContainer styles={props.styles}>
        <Loading text='' />
      </JoyCaptureContainer>
    );
  }

  return (
    <JoyCapture
      joyCapture={joyCapture}
      styles={props.styles}
      noAnimate={props.noAnimate}
      renderer={props.renderer}
      fallbackImageSrc={props.fallbackImageSrc}
      noInitials={props.noInitials}
    />
  );
}

export function MyJoyCapture(props: {
  sessionId?: string;
  styles?: JoyCaptureStyles;
  noAnimate?: boolean;
  renderer?: JoyCaptureRenderer;
  fallbackImageSrc?: string;
  noInitials?: boolean;
}): JSX.Element | null {
  const user = useUser();
  const { privacySettings } = usePrivacySettings();
  const curr = privacySettings?.value?.allowJoyCaptures;
  const prev = usePrevious(curr);
  const { refresh } = useJoyCapture({
    uid: user.id,
    sessionId: props.sessionId,
  });

  // handles the scenario where the users settings change. refreshes the data to be sure it's up-to-date.
  useEffect(() => {
    if (prev !== curr) refresh();
  }, [prev, curr, refresh]);

  return (
    <UserJoyCapture
      uid={user.id}
      styles={props.styles}
      noAnimate={props.noAnimate}
      renderer={props.renderer}
      fallbackImageSrc={props.fallbackImageSrc}
      noInitials={props.noInitials}
    />
  );
}
